/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { viewallstaff, viewByidstaff } from "../../Api/staff";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { viewallFacility } from "../../Api/facility";
import { IoIosInformationCircle } from "react-icons/io";
import StaffattendaceReport from "../../Components/Staff/StaffattendaceReport";
import { viewallCourses } from "../../Api/courses";
import {
  createBatch,
  DestroyBatch,
  UpdateBatch,
  ViewallBatch,
} from "../../Api/batch";
import { ViewAllStudents } from "../../Api/student";
import "./Batch.css";
import moment from "moment";
import { createBatch_log, viewallBatch_log } from "../../Api/batch_log";

const Batchlog = () => {
  const navigate = useNavigate();
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");

  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    type: "",
    users: "",
    startdate: "",
    enddate: "",
    staff: "",
    place: "",
    time: "",
  });
  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [facilitydata, setfacilitydata] = useState([]);
  const [reportView, setreportView] = useState(false);
  const [batchlogdata, setbatchlogdata] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [studentdata, setstudentdata] = useState([]);
  const [allstudentdata, setallstudentdata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allBatchdata, setallBatchdata] = useState([]);
  const [singlestaff, setsinglestaff] = useState([]);

  useEffect(() => {
    getStaffdata();
  }, []);

  const getStaffdata = async () => {
    var alldata = await viewallBatch_log();
    var alldatanew = [];
    for (var i = 0; i < alldata.length; i++) {
      if (alldata[i].batchdata !== null) {
        alldatanew.push({
          id: i + 1,
          data: alldata[i].id,
          batch: alldata[i].batchdata.name,
          message: alldata[i].message,
          date: moment(alldata[i].createdAt).format("YYYY-MM-DD"),
        });
      }
    }
    setbatchlogdata(alldatanew);
  };

  const columns = [
    { field: "id", headerName: "id", width: 100 },
    {
      field: "batch",
      headerName: "Batch",
      width: 300,
      renderCell: (params) => (
        <>
          <h1>Batch: {params.row.batch}</h1>
        </>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      width: 800,
      renderCell: (params) => (
        <>
          <h1>{params.row.message}</h1>
        </>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      renderCell: (params) => (
        <>
          <h1>{params.row.date}</h1>
        </>
      ),
    },
  ];

  const editbtn = async (e) => {
    const checkdata = allBatchdata.filter((data) => data.id === e);
    if (checkdata.length !== 0) {
      const userIds = checkdata[0].users ? checkdata[0].users.split(",") : [];
      const newUsers = userIds.map((userId) => {
        const singleUser = allstudentdata.find((data) => data.id == userId);
        return singleUser || {};
      });

      setstudentdata((prevStudentData) => [
        ...newUsers.filter(
          (newUser) =>
            // Filter out duplicates
            !prevStudentData.some(
              (existingUser) => existingUser.id === newUser.id
            )
        ),
        ...prevStudentData,
      ]);
      setformdata({
        name: checkdata[0].name,
        type: checkdata[0].type.id,
        users: checkdata[0].users,
        startdate: checkdata[0].startdate,
        enddate: checkdata[0].enddate,
        staff: checkdata[0].staff.id,
        place: checkdata[0].place.id,
        time: checkdata[0].time,
      });

      setSelectedItems(userIds.map(Number));
      setupdateid(e);
      setupdatestatus(true);
      setcreatestatus(true);
    }
  };

  return (
    <div>
      <div className="px-8 min-h-screen">
        <div className="flex justify-between py-5 items-center">
          <h1 className="text-2xl py-4 font-black">Batch Log</h1>
        </div>
        <DataGrid rows={batchlogdata} columns={columns} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Batchlog;
