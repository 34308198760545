/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { viewallstaff, viewByidstaff } from "../../Api/staff";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { viewallFacility } from "../../Api/facility";
import { IoIosInformationCircle } from "react-icons/io";
import StaffattendaceReport from "../../Components/Staff/StaffattendaceReport";
import { viewallCourses } from "../../Api/courses";
import {
  createBatch,
  DestroyBatch,
  UpdateBatch,
  ViewallBatch,
} from "../../Api/batch";
import { ViewAllStudents } from "../../Api/student";
import "./Batch.css";
import moment from "moment";
import { createBatch_log } from "../../Api/batch_log";

const BatchList = () => {
  const navigate = useNavigate();
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");

  const [selectedRows, setSelectedRows] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [disablebtn, setdisablebtn] = useState(false);
  const [formdata, setformdata] = useState({
    name: "",
    type: "",
    users: "",
    startdate: "",
    enddate: "",
    staff: "",
    place: "",
    time: "",
  });
  const [updatestatus, setupdatestatus] = useState(false);
  const [updateid, setupdateid] = useState(null);
  const [facilitydata, setfacilitydata] = useState([]);
  const [reportView, setreportView] = useState(false);
  const [staffdata, setstaffdata] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [studentdata, setstudentdata] = useState([]);
  const [allstudentdata, setallstudentdata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [allBatchdata, setallBatchdata] = useState([]);
  const [singlestaff, setsinglestaff] = useState([]);
  const [batchname, setbatchname] = useState("1");

  useEffect(() => {
    getStaffdata();
  }, []);

  const getStaffdata = async () => {
    var alldata = await viewallstaff();
    setstaffdata(alldata);
    if (role == "admin") {
      var allbatchdata = await ViewallBatch();
      if (allbatchdata.length !== 0) {
        const latestBatch = allbatchdata.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )[0];

        if (latestBatch.name.length !== 0) {
          setbatchname(Number(latestBatch.name) + 1);
        }
        setallBatchdata(allbatchdata);
        var alldatanew = [];
        var alluserdata = allbatchdata.flatMap((batch) =>
          batch.users.split(",")
        );
        for (var i = 0; i < allbatchdata.length; i++) {
          alldatanew.push({
            id: i + 1,
            name: allbatchdata[i].name,
            startdate: allbatchdata[i].startdate,
            enddate: allbatchdata[i].enddate,
            status: allbatchdata[i].status,
            type: allbatchdata[i].type.title,
            staff: `${allbatchdata[i].staff.firstname} ${allbatchdata[i].staff.lastname}`,
            place: allbatchdata[i].place.name.split("-")[1],
            time: allbatchdata[i].time,
            data: allbatchdata[i].id,
          });
        }
        setSelectedRows(alldatanew);
      } else {
        setbatchname(1);
      }

      var allfacility = await viewallFacility();
      setfacilitydata(allfacility);

      var allcourse = await viewallCourses();
      setcoursedata(allcourse);

      var allstudent = await ViewAllStudents();
      var all_student = allstudent.filter((data) => {
        if (alluserdata !== undefined) {
          return (
            data.batch == "Current" && !alluserdata.includes(data.id.toString())
          );
        } else {
          return data;
        }
      });
      setstudentdata(all_student);
      setallstudentdata(allstudent);
    } else {
      var allbatchdata1 = await ViewallBatch();
      var mybatchdata = await allbatchdata1.filter((data) => {
        return data.staff.id == adminid;
      });
      const latestBatch = allbatchdata1.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )[0];

      if (latestBatch.name.length !== 0) {
        setbatchname(Number(latestBatch.name) + 1);
      }
      if (mybatchdata.length !== 0) {
        setallBatchdata(mybatchdata);
        var alldatanew1 = [];
        var alluserdata1 = mybatchdata.flatMap((batch) =>
          batch.users.split(",")
        );
        for (var i = 0; i < mybatchdata.length; i++) {
          alldatanew1.push({
            id: i + 1,
            name: mybatchdata[i].name,
            startdate: mybatchdata[i].startdate,
            enddate: mybatchdata[i].enddate,
            status: mybatchdata[i].status,
            type: mybatchdata[i].type.title,
            staff: `${mybatchdata[i].staff.firstname} ${mybatchdata[i].staff.lastname}`,
            place: mybatchdata[i].place.name.split("-")[1],
            time: mybatchdata[i].time,
            data: mybatchdata[i].id,
          });
        }
        setSelectedRows(alldatanew1);
      }

      var allfacility = await viewallFacility();
      setfacilitydata(allfacility);

      var allcourse = await viewallCourses();
      setcoursedata(allcourse);

      var allstudent = await ViewAllStudents();
      var all_student = allstudent.filter((data) => {
        return (
          data.batch == "Current" && !alluserdata1.includes(data.id.toString())
        );
      });
      setstudentdata(all_student);
      setallstudentdata(allstudent);
    }
    if (alldata.length !== 0) {
      var singlestaff = await alldata.filter((data) => {
        return data.id == adminid;
      });
      setsinglestaff(singlestaff);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name == "startdate") {
      var newdate = moment(event.target.value)
        .add(105, "days")
        .format("YYYY-MM-DD");
      setformdata((values) => ({ ...values, enddate: newdate }));
    }
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const savebtn = async () => {
    if (batchname.length === 0) {
      toast.error("Please Enter Batch Name...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.type.length === 0) {
      toast.error("Please Select Course...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.startdate.length === 0) {
      toast.error("Please enter Batch Start Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.enddate.length === 0) {
      toast.error("Please enter Batch End Date...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.staff.length === 0) {
      toast.error("Please Select Staff...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.place.length === 0) {
      toast.error("Please Select Place...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (formdata.time.length === 0) {
      toast.error("Please Select Batch Time...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      setdisablebtn(true);
      formdata["status"] = "Not Approval";
      formdata["name"] = batchname;
      formdata["users"] = selectedItems.toString();
      var createdata = await createBatch(formdata);
      if (createdata.message === "SUCCESS") {
        toast.success("Batch Details added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        var newobj = {
          type: "New Batch Create",
          message:
            role == "admin"
              ? "New Batch Craeted by admin"
              : `New Batch Created by staff ${singlestaff[0].firstname} ${singlestaff[0].lastname}`,
          batchid: createdata.data.id,
          role: role,
        };
        await createBatch_log(newobj);
        setdisablebtn(false);
        setSelectedItems([]);
        setTimeout(() => {
          getStaffdata();
          setcreatestatus(false);
        }, 2000);
      }
    }
  };

  const columns = [
    // { field: "id", headerName: "id", width: 50 },
    {
      field: "name",
      headerName: "Name",
      width: 120,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            Batch: {params.row.name}
          </h1>
        </>
      ),
    },

    {
      field: "type",
      headerName: "Course",
      width: 250,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.type}
          </h1>
        </>
      ),
    },
    {
      field: "place",
      headerName: "Place",
      width: 150,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.place}
          </h1>
        </>
      ),
    },
    {
      field: "staff",
      headerName: "Staff",
      width: 200,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.staff}
          </h1>
        </>
      ),
    },
    {
      field: "startdate",
      headerName: "Start Date",
      width: 140,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.startdate}
          </h1>
        </>
      ),
    },
    {
      field: "enddate",
      headerName: "End Date",
      width: 140,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.enddate}
          </h1>
        </>
      ),
    },
    {
      field: "time",
      headerName: "Batch Time",
      width: 180,
      renderCell: (params) => (
        <>
          <h1
            onClick={() => {
              getdata(params.row.data);
            }}
          >
            {params.row.time}
          </h1>
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <>
          {role == "admin" ? (
            <select
              defaultValue={params.row.status}
              onChange={(e) => {
                changeStatus(e.target.value, params.row.data);
              }}
              className="border-2 p-2 rounded"
            >
              <option value="">Select</option>
              <option value="Completed">Completed</option>
              <option value="Ongoing">Ongoing</option>
              <option value="Upcomming">Upcomming</option>
              <option value="Not Approval">Not Approval</option>
              <option value="Approved">Approved</option>
            </select>
          ) : (
            <h1
              onClick={() => {
                editbtn(params.row.status);
              }}
            >
              {params.row.status}
            </h1>
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Action",
      width: 150,
      renderCell: (params) =>
        role == "admin" ? (
          <div className="flex gap-2">
            <IconButton className="m-5">
              <IoIosInformationCircle
                onClick={() => {
                  getdata(params.row.data);
                }}
              />
            </IconButton>
            <IconButton className="m-5">
              <Edit
                onClick={() => {
                  editbtn(params.row.data);
                }}
              />
            </IconButton>
            <IconButton className="m-5">
              <DeleteIcon
                onClick={() => {
                  deletebtn(params.row.data);
                }}
              />
            </IconButton>
          </div>
        ) : role == "staff" &&
          singlestaff.length !== 0 &&
          singlestaff[0].email == "athirooban@codepurple.in" ? (
          <div className="flex gap-2">
            <IconButton className="m-5">
              <IoIosInformationCircle
                onClick={() => {
                  getdata(params.row.data);
                }}
              />
            </IconButton>
            <IconButton className="m-5">
              <Edit
                onClick={() => {
                  editbtn(params.row.data);
                }}
              />
            </IconButton>
            <IconButton className="m-5">
              <DeleteIcon
                onClick={() => {
                  deletebtn(params.row.data);
                }}
              />
            </IconButton>
          </div>
        ) : (
          <div className="flex gap-2">
            <IconButton className="m-5">
              <IoIosInformationCircle
                onClick={() => {
                  getdata(params.row.data);
                }}
              />
            </IconButton>
          </div>
        ),
    },
  ];

  const changeStatus = async (e, id) => {
    await UpdateBatch({ id: id, status: e });
    getStaffdata();
    var newobj = {
      type: `${e} Batch`,
      message:
        role == "admin"
          ? `Batch ${e} by admin`
          : `Batch ${e} by staff ${singlestaff[0].firstname} ${singlestaff[0].lastname}`,
      batchid: updateid,
      role: role,
    };
    await createBatch_log(newobj);
  };

  const getdata = async (e) => {
    navigate(`/batchinfo/${e}`);
  };

  const deletebtn = async (e) => {
    var result = confirm("Are you sure to delete this Batch?");
    if (result === true) {
      var deletedata = await DestroyBatch({ id: e });
      if (deletedata === "Deleted Successfully") {
        toast.success("Batch Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getStaffdata();
      }
    }
  };

  const editbtn = async (e) => {
    const checkdata = allBatchdata.filter((data) => data.id === e);
    if (checkdata.length !== 0) {
      const userIds = checkdata[0].users ? checkdata[0].users.split(",") : [];
      const newUsers = userIds.map((userId) => {
        const singleUser = allstudentdata.find((data) => data.id == userId);
        return singleUser || {};
      });

      setstudentdata((prevStudentData) => [
        ...newUsers.filter(
          (newUser) =>
            // Filter out duplicates
            !prevStudentData.some(
              (existingUser) => existingUser.id === newUser.id
            )
        ),
        ...prevStudentData,
      ]);
      setformdata({
        name: checkdata[0].name,
        type: checkdata[0].type.id,
        users: checkdata[0].users,
        startdate: checkdata[0].startdate,
        enddate: checkdata[0].enddate,
        staff: checkdata[0].staff.id,
        place: checkdata[0].place.id,
        time: checkdata[0].time,
      });

      setSelectedItems(userIds.map(Number));
      setupdateid(e);
      setupdatestatus(true);
      setcreatestatus(true);
    }
  };

  const updatbtn = async () => {
    formdata["id"] = updateid;
    formdata["users"] = selectedItems.toString();
    setdisablebtn(true);
    var updatedata = await UpdateBatch(formdata);
    if (updatedata === "Updated Successfully") {
      var newobj = {
        type: "Update Batch",
        message:
          role == "admin"
            ? "Batch Updated by admin"
            : `Batch Updated by staff ${singlestaff[0].firstname} ${singlestaff[0].lastname}`,
        batchid: updateid,
        role: role,
      };
      await createBatch_log(newobj);
      toast.success("Batch Details Updated Successfully...", {
        autoClose: 2000,
        transition: Slide,
      });
      setdisablebtn(false);
      setTimeout(() => {
        getStaffdata();
        setcreatestatus(false);
      }, 2000);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div>
      {reportView == false ? (
        createstatus === false ? (
          <div className="px-8 min-h-screen">
            <div className="flex justify-between py-5 items-center">
              <h1 className="text-2xl py-4 font-black">Batch Information</h1>
              {role == "staff" &&
              singlestaff.length !== 0 &&
              singlestaff[0].email == "athirooban@codepurple.in" ? (
                <div className="flex gap-2 items-center">
                  <button
                    className="bg-black text-white rounded w-[10rem] font-md py-4"
                    onClick={() => {
                      setcreatestatus(true);
                      setformdata({
                        name: "",
                        type: "",
                        users: "",
                        startdate: "",
                        enddate: "",
                        staff: "",
                        place: "",
                        time: "",
                      });
                      setupdateid(null);
                      setupdatestatus(false);
                    }}
                  >
                    Create
                  </button>
                </div>
              ) : (
                role == "admin" && (
                  <div className="flex gap-2 items-center">
                    <button
                      className="bg-black text-white rounded w-[10rem] font-md py-4"
                      onClick={() => {
                        setcreatestatus(true);
                        setformdata({
                          name: "",
                          type: "",
                          users: "",
                          startdate: "",
                          enddate: "",
                          staff: "",
                          place: "",
                          time: "",
                        });
                        setupdateid(null);
                        setupdatestatus(false);
                      }}
                    >
                      Create
                    </button>
                  </div>
                )
              )}
            </div>
            <DataGrid rows={selectedRows} columns={columns} />
          </div>
        ) : (
          <div className="px-14 min-h-screen">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setcreatestatus(false);
                setformdata({
                  name: "",
                  type: "",
                  users: "",
                  startdate: "",
                  enddate: "",
                  staff: "",
                  place: "",
                  time: "",
                });
                setupdateid(null);
                getStaffdata();
              }}
            >
              <WestIcon />
              {updatestatus === true ? (
                <h1 className="text-2xl py-4 font-black">Edit Batch Details</h1>
              ) : (
                <h1 className="text-2xl py-4 font-black">Add New Batch</h1>
              )}
            </div>
            <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                <div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Batch Name
                    </h1>
                    <input
                      placeholder="Batch Name"
                      type="number"
                      name="name"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      value={
                        formdata.name.length == 0 ? batchname : formdata.name
                      }
                      disabled
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Batch Course
                    </h1>
                    <select
                      name="type"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.type}
                    >
                      <option value="">Batch Course</option>
                      {coursedata.length !== 0
                        ? coursedata.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.title}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>

                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Batch Start Date
                    </h1>
                    <input
                      placeholder="Joining Date"
                      type="date"
                      name="startdate"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.startdate}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Batch End Date
                    </h1>
                    <input
                      placeholder="Joining Date"
                      type="date"
                      name="enddate"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.enddate}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Place
                    </h1>
                    <select
                      name="place"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.place}
                    >
                      <option value="">Select Place</option>
                      {facilitydata.length !== 0
                        ? facilitydata.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Batch Time
                    </h1>
                    <select
                      name="time"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.time}
                    >
                      <option value="">Select Batch Time</option>
                      <option value="10.00 AM - 11.00 AM">
                        10.00 AM - 11.00 AM
                      </option>
                      <option value="11.00 AM - 12.00 PM">
                        11.00 AM - 12.00 PM
                      </option>
                      <option value="12.00 PM - 01.00 PM">
                        12.00 PM - 01.00 PM
                      </option>
                      <option value="01.00 PM - 02.00 PM">
                        01.00 PM - 02.00 PM
                      </option>
                      <option value="02.00 PM - 03.00 PM">
                        02.00 PM - 03.00 PM
                      </option>
                      <option value="03.00 PM - 04.00 PM">
                        03.00 PM - 04.00 PM
                      </option>
                      <option value="04.00 PM - 05.00 PM">
                        04.00 PM - 05.00 PM
                      </option>
                      <option value="05.00 PM - 06.00 PM">
                        05.00 PM - 06.00 PM
                      </option>
                      <option value="06.00 PM - 07.00 PM">
                        06.00 PM - 07.00 PM
                      </option>
                      <option value="07.00 PM - 08.00 PM">
                        07.00 PM - 08.00 PM
                      </option>
                    </select>
                  </div>
                </div>
                <div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Staff
                    </h1>
                    <select
                      name="staff"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      defaultValue={formdata.staff}
                    >
                      <option value="">Select Staff</option>
                      {staffdata.length !== 0
                        ? staffdata.map((data, index) => (
                            <option value={data.id} key={index}>
                              {data.firstname} {data.lastname}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                      Select Students
                    </h1>
                    <div className="mt-5 studentlistdiv">
                      {studentdata.length !== 0
                        ? studentdata.map((item) => (
                            <div key={item.id} className="mb-3">
                              <input
                                className="mr-2"
                                type="checkbox"
                                id={`checkbox-${item.id}`}
                                checked={selectedItems.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                              <label htmlFor={`checkbox-${item.id}`}>
                                {item.name}
                              </label>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-8">
                {updatestatus === true ? (
                  disablebtn === true ? (
                    <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                      Please Wait...
                    </button>
                  ) : (
                    <button
                      className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                      onClick={updatbtn}
                    >
                      Update
                    </button>
                  )
                ) : disablebtn === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={savebtn}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <StaffattendaceReport setreportView={setreportView} />
      )}

      <ToastContainer />
    </div>
  );
};

export default BatchList;
