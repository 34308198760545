/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { IoMdTrash } from "react-icons/io";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WestIcon from "@mui/icons-material/West";
import JoditEditor from "jodit-react";
import {
  TicketbyStudent,
  createTicket,
  destroyTicket,
  updateTicket,
} from "../Api/ticket";
import HTMLReactParser from "html-react-parser";
import { createTicket_reply, ticket_replybyticket } from "../Api/ticket_reply";
import { firebase } from "../database/firebase";
import { MdEditSquare } from "react-icons/md";

const StudentTicket = () => {
  const editorRef = useRef(null);

  const [projectdata, setprojectdata] = useState([]);
  const [newmodel, setnewmodel] = useState(false);
  const [singledata, setsingledata] = useState([]);
  const [createstatus, setcreatestatus] = useState(false);
  const [formdata, setformdata] = useState({
    title: "",
    subject: "",
  });
  const [clicked, setclicked] = useState(false);
  const [content, setContent] = useState("");
  const [replystatus, setreplystatus] = useState(false);
  const [replydata, setreplydata] = useState([]);
  const [message, setmessage] = useState("");
  const [attachmentdata, setattachmentdata] = useState([]);
  const [updateid, setupdateid] = useState(null);

  const config = {
    // Toolbar options
    toolbar: true, // Show the toolbar
    toolbarAdaptive: false, // Adaptive toolbar based on window width
    toolbarSticky: true, // Stick the toolbar to the top of the editor
    toolbarButtonSize: "middle", // Size of toolbar buttons: 'small', 'middle', 'big'
    toolbarButtonIcons: true, // Show icons on toolbar buttons
    toolbarButtonText: false, // Show text on toolbar buttons

    // Editor appearance
    theme: "default", // Editor theme: 'default', 'dark'
    language: "en", // Editor language

    // Editor behavior
    spellcheck: true, // Enable spellchecking
    showCharsCounter: true, // Show character counter
    showWordsCounter: true, // Show word counter
    hidePlaceholderOnFocus: false, // Hide the placeholder when the editor gains focus
    showXPathInStatusbar: true, // Show the XPath of the selected element in the status bar

    // Editor content
    allowScript: true, // Allow <script> tags in the editor content
    sanitize: false, // Sanitize the editor content to remove potentially unsafe elements and attributes

    // Plugins and features
    enableDragAndDropFileToEditor: true, // Enable dragging and dropping files into the editor to insert them
    enableResizeEditor: true, // Enable resizing of the editor
    enableSearchReplace: true, // Enable search and replace functionality
    enableCodeMirror: true, // Enable CodeMirror syntax highlighting for HTML source mode
  };

  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  useEffect(() => {
    getProjectdata();
  }, [adminid]);
  const getProjectdata = async () => {
    if (adminid !== null) {
      var projectdata = await TicketbyStudent({ id: adminid });
      if (projectdata.length !== 0) {
        setprojectdata(projectdata);
      }
    }
  };
  const openmodel = async (data) => {
    setnewmodel(true);
    setsingledata([data]);
    var checkreplydata = await ticket_replybyticket({ id: data.id });
    setreplydata(checkreplydata);
  };

  const deletebtn = async (e) => {
    var result = await confirm("Are you sure you want to delete this Ticket?");
    if (result === true) {
      var deletebtn = await destroyTicket({ id: e });
      if (deletebtn === "Deleted Successfully") {
        toast.success("Ticket Deleted Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        getProjectdata();
      }
    }
  };
  const handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please Enter Ticket Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    if (content.length == 0) {
      toast.error("Please Enter Subject...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["subject"] = content;
      setclicked(true);
      formdata["student"] = adminid;
      formdata["status"] = "true";
      formdata["attachment"] = JSON.stringify(attachmentdata);
      var createproject = await createTicket(formdata);
      if (createproject.message === "SUCCESS") {
        toast.success("Ticket added Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getProjectdata();
          setcreatestatus(false);
        }, 2000);
        setContent("");
        setclicked(false);
      } else {
        setclicked(false);
        toast.success(createproject.message, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  const sendbtn = async () => {
    var newobj = {
      student: singledata[0].Student.id,
      message: message,
      admin: null,
      read: "false",
      ticket: singledata[0].id,
      from: "student",
    };
    var createdata = await createTicket_reply(newobj);
    if (createdata.message === "SUCCESS") {
      var checkreplydata = await ticket_replybyticket({ id: singledata[0].id });
      setreplydata(checkreplydata);
      setreplystatus(false);
    }
  };
  useEffect(() => {
    getchatdata();
  }, [newmodel]);
  const getchatdata = async () => {
    if (newmodel === true) {
      setreplydata([]);
      if (singledata.length !== 0) {
        var checkreplydata = await ticket_replybyticket({
          id: singledata[0].id,
        });
        setreplydata(checkreplydata);
      }
      // setTimeout(() => {
      //   getchatdata();
      // }, 2000);
    }
  };
  const closeticket = (e) => {
    var newobj = {
      status: "false",
      id: e,
    };
    updateTicket(newobj);
    setnewmodel(false);
    getProjectdata();
  };
  const geturl = async (e) => {
    var imagearray = [];
    const files = Array.from(e.target.files);
    toast.info("Please Wait Image is uploading...", {
      autoClose: 2000,
      transition: Slide,
    });
    setclicked(true);

    for (var i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-loop-func
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref("logo/" + files[i].name);
        storageRef.put(files[i]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      });
      var imgurl1 = await file13;
      imagearray.push(imgurl1);
    }

    if (files.length === imagearray.length) {
      setattachmentdata(imagearray);
      toast.success("Attachment Uploaded..", {
        autoClose: 2000,
        transition: Slide,
      });

      setclicked(false);
    }
  };
  const editbtn = async (data) => {
    setformdata({
      title: data.title,
      subject: data.subject,
    });
    setContent(data.subject);
    setcreatestatus(true);
    setupdateid(data.id);
    setattachmentdata(JSON.parse(data.attachment));
  };
  const updatebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please Enter Ticket Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    }
    if (content.length == 0) {
      toast.error("Please Enter Subject...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["subject"] = content;
      setclicked(true);
      formdata["id"] = updateid;
      formdata["attachment"] = JSON.stringify(attachmentdata);
      var updatedata = await updateTicket(formdata);
      if (updatedata === "Updated Successfully") {
        toast.success("Ticket Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setTimeout(() => {
          getProjectdata();
          setcreatestatus(false);
          setupdateid(null);
          setContent("");
        }, 2000);
        setContent("");
        setclicked(false);
      } else {
        setclicked(false);
        toast.success(updatedata, {
          autoClose: 2000,
          transition: Slide,
        });
      }
    }
  };
  return (
    <>
      {newmodel === false ? (
        createstatus === false ? (
          <div className="px-14 pb-5">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl py-4 font-black pb-5">My Ticket</h1>
              <button
                className="bg-black text-white rounded w-[10rem] font-md py-4"
                onClick={() => {
                  setcreatestatus(true);
                  setformdata({
                    title: "",
                    subject: "",
                  });
                  setupdateid(null);
                  setContent("");
                }}
              >
                Add Ticket
              </button>
            </div>
            <hr className="py-5 border-black" />
            <div className="grid md:grid-cols-1 grid-cols-1 gap-6">
              {projectdata.length !== 0
                ? projectdata.map((data, index) => (
                    <div
                      className="flex flex-col rounded-sm justify-evenly shadow-2xl bg-white rounded-xl"
                      key={index}
                    >
                      <div className="px-3">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-4 items-center">
                            <h1 className="text-lg pt-2 font-bold">
                              {data.title}
                            </h1>
                            {data.status === "false" ? (
                              <h6 className="text-red-600 text-md font-bold mt-2">
                                ( Closed )
                              </h6>
                            ) : null}
                          </div>
                          <div className="flex gap-2">
                            <MdEditSquare
                              className="h-8 text-2xl cursor-pointer"
                              onClick={() => {
                                editbtn(data);
                              }}
                            />
                            <IoInformationCircle
                              className="h-8 text-2xl cursor-pointer"
                              onClick={() => {
                                openmodel(data);
                              }}
                            />
                            <IoMdTrash
                              className="h-8 text-2xl cursor-pointer text-red-500"
                              onClick={() => {
                                deletebtn(data.id);
                              }}
                            />
                          </div>
                        </div>
                        <div className="pb-5">
                          <p className="mt-2 smalltextsubject">
                            {HTMLReactParser(data.subject)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        ) : (
          <div className="px-14 min-h-screen">
            <div
              className="flex gap-2 items-center cursor-pointer"
              onClick={() => {
                setcreatestatus(false);
                setformdata({
                  title: "",
                  subject: "",
                });
                setupdateid(null);
                setContent("");
              }}
            >
              <WestIcon />
              {updateid === null ? (
                <h1 className="text-2xl py-4 font-black">Add New Ticket</h1>
              ) : (
                <h1 className="text-2xl py-4 font-black">Update Ticket</h1>
              )}
            </div>
            <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                <div>
                  <div className="py-3">
                    <h1 className="text-lg">Ticket Title</h1>
                    <input
                      placeholder="Ticket Title"
                      type="text"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      onChange={handleChange}
                      value={formdata.title}
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg">Subject</h1>
                    <JoditEditor
                      ref={editorRef}
                      value={content}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                      className="subjecttag"
                    />
                  </div>
                  <div className="py-3">
                    <h1 className="text-lg">Ticket Attachment Image</h1>
                    <input
                      placeholder="Ticket Title"
                      type="file"
                      name="title"
                      className="border-2 p-2 w-full py-2 rounded"
                      multiple
                      onChange={geturl}
                    />
                  </div>
                </div>
              </div>
              <div className="py-8">
                {clicked === true ? (
                  <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
                    Please Wait...
                  </button>
                ) : updateid === null ? (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={savebtn}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
                    onClick={updatebtn}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          {singledata.length !== 0 ? (
            <>
              <div className="px-14 pb-5">
                <div
                  className="flex gap-2 items-center cursor-pointer"
                  onClick={() => {
                    setnewmodel(false);
                    setsingledata([]);
                  }}
                >
                  <MdOutlineKeyboardBackspace className="text-3xl cursor-pointer" />
                  <h1 className="text-xl py-4 font-black cursor-pointer">
                    Back
                  </h1>
                </div>
                <hr className="py-5 mt-5 border-black" />
                <div className="flex flex-col px-4 py-2">
                  <h2 className="font-bold text-3xl text-center">
                    {singledata[0].title}
                  </h2>
                </div>
                <div className="px-3">
                  <div className="py-5">
                    <div className="flex justify-between">
                      <p className="font-bold text-xl">Subject </p>
                      {singledata[0].status === "true" ? (
                        <button
                          className="px-4 py-2 bg-red-500 rounded text-white text-sm"
                          onClick={() => {
                            closeticket(singledata[0].id);
                          }}
                        >
                          Close Ticket
                        </button>
                      ) : null}
                    </div>
                    <p className="mt-2">
                      {HTMLReactParser(singledata[0].subject)}
                    </p>
                  </div>
                  <hr className="py-5 border-gray-500" />
                  {replydata.length !== 0 ? (
                    <div className="messagediv">
                      {replydata.length !== 0
                        ? replydata.map((data, index) =>
                            data.from === "student" ? (
                              <div className="mt-2 px-4" key={index}>
                                {" "}
                                <div className="text-right float-right bg-green-400 px-4 rounded w-fit">
                                  {HTMLReactParser(data.message)}
                                </div>
                                <br />
                              </div>
                            ) : (
                              <p className="bg-gray-400 text-left px-4 rounded w-fit">
                                {HTMLReactParser(data.message)}
                              </p>
                            )
                          )
                        : null}
                    </div>
                  ) : null}
                  {singledata[0].status === "true" ? (
                    replystatus === false ? (
                      <div className="flex gap-4 mt-5">
                        <button
                          className="px-4 py-2 bg-green-500 rounded text-white"
                          onClick={() => {
                            setreplystatus(true);
                          }}
                        >
                          Reply
                        </button>
                        <button
                          className="px-4 py-2 bg-blue-500 rounded text-white"
                          onClick={() => {
                            getchatdata();
                          }}
                        >
                          Refresh
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="py-3">
                          <JoditEditor
                            ref={editorRef}
                            value={message}
                            config={config}
                            tabIndex={1}
                            onBlur={(newContent) => setmessage(newContent)}
                            className="subjecttag"
                          />
                        </div>
                        <div className="flex gap-4 mt-3">
                          <button
                            className="px-4 py-2 bg-green-500 rounded text-white"
                            onClick={sendbtn}
                          >
                            Send
                          </button>
                          <button
                            className="px-4 py-2 bg-red-500 rounded text-white"
                            onClick={() => {
                              setreplystatus(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="px-4 py-2 bg-blue-500 rounded text-white"
                            onClick={() => {
                              getchatdata();
                            }}
                          >
                            Refresh
                          </button>
                        </div>
                      </>
                    )
                  ) : (
                    <h6 className="text-red-600 text-center text-xl font-bold">
                      Ticket Closed
                    </h6>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default StudentTicket;
