/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { viewallModules } from "../../Api/modules";
import {
  createTechstack,
  updateTechstack,
  viewByidTechstack,
} from "../../Api/techstack";

const EditTechstack = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [formdata, setformdata] = useState({
    title: "",
  });
  const [disablebtn, setdisablebtn] = useState(false);
  const [lessondata, setlessondata] = useState([]);
  const [selectedlesson, setselectedlesson] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  const savebtn = async () => {
    if (formdata.title.length === 0) {
      toast.error("Please enter Title...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else if (selectedlesson.length === 0) {
      toast.error("Please Select Modules...", {
        autoClose: 2000,
        transition: Slide,
      });
    } else {
      formdata["name"] = formdata.title;
      formdata["modules"] = JSON.stringify(selectedlesson);
      formdata["id"] = id;
      setdisablebtn(true);
      var createdata = await updateTechstack(formdata);
      if (createdata === "Updated Successfully") {
        toast.success("Tech Stack Updated Successfully...", {
          autoClose: 2000,
          transition: Slide,
        });
        setdisablebtn(false);
        setTimeout(() => {
          navigate("/courses");
        }, 2000);
      }
    }
  };

  useEffect(() => {
    getalllesson();
  }, [id]);

  const getalllesson = async () => {
    var alldata = await viewallModules();
    setlessondata(alldata);
    var Techdata = await viewByidTechstack({ id: id });
    if (Techdata.length !== 0) {
      setformdata({
        title: Techdata[0].name,
      });
      setselectedlesson(JSON.parse(Techdata[0].modules));
    }
  };

  const handlelessonSelection = (lesson) => {
    const updatedSelectedlessons = [...selectedlesson];
    if (updatedSelectedlessons.includes(lesson)) {
      updatedSelectedlessons.splice(updatedSelectedlessons.indexOf(lesson), 1);
    } else {
      updatedSelectedlessons.push(lesson);
    }
    setselectedlesson(updatedSelectedlessons);
  };

  return (
    <div className="px-14 min-h-screen">
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          navigate("/courses");
        }}
      >
        <WestIcon />
        <h1 className="text-2xl py-4 font-black">Create New Tech Stack</h1>
      </div>

      <div className="w-full bg-white rounde shadow-2xl p-7 my-5 ">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-5">
          <div>
            <>
              <div className="py-3">
                <h1 className="text-lg after:content-['*'] after:ml-0.5 after:text-red-500">
                  Title
                </h1>
                <input
                  placeholder="Title"
                  type="text"
                  name="title"
                  className="border-2 p-2 w-full py-2 rounded"
                  onChange={handleChange}
                  defaultValue={formdata.title}
                />
              </div>
              <div className="py-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div className="leftsidediv border-r-2 border-gray-300">
                    <h1 className="text-lg">Select Modules</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some(
                            (obj) => obj == data.id
                          ) ? null : (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div>
                    <h1 className="text-lg">Selected Modules</h1>
                    {lessondata.length !== 0
                      ? lessondata.map((data, index) =>
                          selectedlesson.some((obj) => obj == data.id) ? (
                            <div className="py-2 pl-5" key={index}>
                              <input
                                type="checkbox"
                                id={`lesson-${index}`}
                                checked={selectedlesson.includes(data.id)}
                                onChange={() => handlelessonSelection(data.id)}
                              />
                              <label className="" htmlFor={`lesson-${index}`}>
                                {" "}
                                {data.title}
                              </label>
                            </div>
                          ) : null
                        )
                      : null}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
        <div className="py-8">
          {disablebtn === false ? (
            <button
              className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5"
              onClick={savebtn}
            >
              Update
            </button>
          ) : (
            <button className="bg-black text-white py-3 rounded w-[20rem] float-right mb-5">
              Please wait...
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditTechstack;
