import { Lesson } from "../axios";

export const viewallLesson = async (data) => {
  var viewallLesson = await Lesson.post(`/viewallLesson`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallLesson;
};

export const createLesson = async (data) => {
  var createLesson = await Lesson.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createLesson;
};

export const viewByidLesson = async (data) => {
  var viewByidLesson = await Lesson.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidLesson;
};

export const updateLesson = async (data) => {
  var updateLesson = await Lesson.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateLesson;
};

export const destroyLesson = async (data) => {
  var destroyLesson = await Lesson.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyLesson;
};
