/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ViewByidBatch } from "../../Api/batch";
import { ViewCoursesInfo } from "../../Api/courses";
import WestIcon from "@mui/icons-material/West";
import { viewallstaff } from "../../Api/staff";

const StudentModule = ({ id, setviewoption }) => {
  const [moduledata, setmoduledata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setloading] = useState(false);
  const [staffdata, setstaffdata] = useState([]);
  const [techdata, settechdata] = useState([]);

  useEffect(() => {
    GetCourseInfo();
  }, [id]);

  const GetCourseInfo = async () => {
    const batchResponse = await ViewByidBatch({ id: id });
    if (batchResponse.length !== 0) {
      setloading(true);
      const batch = batchResponse[0];

      const completedModules = batch.completed_module
        ? batch.completed_module.split(",")
        : [];
      if (completedModules.join(",") !== selectedItems.join(",")) {
        setSelectedItems(completedModules);
      }

      const courseResponse = await ViewCoursesInfo({ id: batch.type });
      if (courseResponse.length !== 0) {
        const course = courseResponse[0];
        if (course.modules.length !== 0 && course.modules !== moduledata) {
          setmoduledata(course.modules);
          setloading(false);
        }
        if (course.technologies.length !== 0) {
          settechdata(course.technologies);
        }
      }
    }
    var allstaffdata = await viewallstaff();
    setstaffdata(allstaffdata);
  };

  return (
    <div>
      <div
        className="flex gap-2 items-center cursor-pointer"
        onClick={() => {
          setviewoption(false);
        }}
      >
        <WestIcon />
        <h1 className="text-2xl py-4 font-black">Course Modules</h1>
      </div>
      {techdata.length !== 0 ? (
        <div className="stepper">
          {techdata.map((item, index) => {
            const moduleArray = JSON.parse(item.modules);
            const isActive = moduleArray.every((module) =>
              selectedItems.includes(String(module))
            );

            return (
              <React.Fragment key={index}>
                <div className={`step ${isActive ? "active" : ""}`}>
                  <div className="circle-container">
                    <span className="circle">{isActive ? "✔" : "✔"}</span>
                    <span className="label">{item.name}</span>
                  </div>
                </div>
                {index < techdata.length - 1 && <div className="line"></div>}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}
      <table className="w-full text-center mt-5">
        <thead>
          <tr>
            <th>Day</th>
            <th>Heading</th>
            <th>Syllabus</th>
            <th>Status</th>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {loading === false ? (
            moduledata.length !== 0 ? (
              (() => {
                let moduleDayCounter = 0;

                return moduledata.map((data, index) => {
                  const currentDay =
                    data.type === "Module" ? `Day ${++moduleDayCounter}` : null;

                  return (
                    <tr key={index}>
                      <td>{currentDay}</td>
                      <td>{data.title}</td>

                      {data.type === "Module" ? (
                        <>
                          <td>
                            <ul className="list-disc pl-5 text-start">
                              {data.lesson_data && data.lesson_data.length !== 0
                                ? data.lesson_data.map((item, lessonIndex) => (
                                    <li key={lessonIndex}>{item.title}</li>
                                  ))
                                : "No lessons available"}
                            </ul>
                          </td>
                          <td>
                            {selectedItems.includes(String(data.id))
                              ? "Completed"
                              : "Upcoming"}
                          </td>
                          <td>
                            {data.task.length !== 0 ? (
                              <button
                                className="bg-blue-500 text-white p-2 rounded"
                                onClick={() =>
                                  window.open(data.task, "__blank")
                                }
                              >
                                View
                              </button>
                            ) : (
                              "No task available"
                            )}
                          </td>
                        </>
                      ) : data.type === "Evaluation" ? (
                        <>
                          <td colSpan={1}>
                            <select
                              className="p-2 border rounded w-[80%]"
                              value={data.author}
                              disabled
                            >
                              <option value="">Select Staff</option>
                              {staffdata.length !== 0 &&
                                staffdata.map((staff) => (
                                  <option key={staff.id} value={staff.id}>
                                    {staff.firstname} {staff.lastname}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td>
                            {selectedItems.includes(String(data.id))
                              ? "Completed"
                              : "Upcoming"}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                });
              })()
            ) : (
              <tr>
                <td colSpan={7}>No Modules Available</td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={7}>Please Wait...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default StudentModule;
