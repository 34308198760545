import { Techstack } from "../axios";

export const viewallTechstack = async (data) => {
  var viewallTechstack = await Techstack.post(`/viewallteachstack`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallTechstack;
};

export const createTechstack = async (data) => {
  var createTechstack = await Techstack.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createTechstack;
};

export const destroyTechstack = async (data) => {
  var destroyTechstack = await Techstack.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyTechstack;
};

export const viewByidTechstack = async (data) => {
  var viewByidTechstack = await Techstack.post(`/viewByid`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewByidTechstack;
};

export const updateTechstack = async (data) => {
  var updateTechstack = await Techstack.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateTechstack;
};
