import { Batch_log } from "../axios";

export const viewallBatch_log = async (data) => {
  var viewallBatch_log = await Batch_log.post(`/viewallbatch_log`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return viewallBatch_log;
};

export const createBatch_log = async (data) => {
  var createBatch_log = await Batch_log.post(`/create`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return createBatch_log;
};

export const updateBatch_log = async (data) => {
  var updateBatch_log = await Batch_log.post(`/update`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return updateBatch_log;
};
export const destroyBatch_log = async (data) => {
  var destroyBatch_log = await Batch_log.post(`/destroy`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });
  return destroyBatch_log;
};
