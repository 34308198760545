/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AttendancebyStudent, addAttendance } from "../Api/attendance";
import { FaRegCheckCircle } from "react-icons/fa";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Myattendance = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");

  const columns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "name",
      headerName: "Student Name",
      width: 300,
    },
    {
      field: "date",
      headerName: "Check In Date",
      width: 250,
    },
    {
      field: "time",
      headerName: "Check In Time",
      width: 250,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
    },
  ];

  useEffect(() => {
    getAttendancedata();
  }, [adminid]);

  const getAttendancedata = async () => {
    var myattendance = await AttendancebyStudent({ id: adminid });
    if (myattendance.length !== 0) {
      const dateSet = new Set(); // Track unique dates
      const alldata = [];
      let idCounter = 1; // External counter for sequential IDs

      myattendance.forEach((record) => {
        const formattedDate = moment(record.date).format("DD-MM-YYYY"); // Format the date
        if (!dateSet.has(formattedDate)) {
          dateSet.add(formattedDate); // Add formatted date to the Set
          alldata.push({
            id: idCounter++, // Increment ID counter
            name: record.Student.name,
            date: formattedDate,
            time: record.time,
            status: record.status === "true" ? "Present" : "Absent",
          });
        }
      });

      setSelectedRows(alldata);
    }
  };

  return (
    <div className="w-full px-14 min-h-screen">
      <div className="flex justify-between py-5 items-center">
        <h1 className="text-2xl py-4 font-black">Attendance </h1>
      </div>
      <div style={{ width: "100%" }}>
        <DataGrid
          rows={selectedRows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          align={"center"}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Myattendance;
