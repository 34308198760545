import axios from "axios";

export const Student = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/student`,
});

export const Lead = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/lead`,
});

export const Staff = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/staff`,
});

export const Expense = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/expense`,
});

export const Return = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/return`,
});

export const Lesson = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/lesson`,
});

export const Admin = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
});

export const Modules = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/modules`,
});

export const Courses = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/courses`,
});

export const Appointment = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/appointment`,
});

export const Facility = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/facility`,
});

export const Payment = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/payment`,
});

export const Phonepe = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/phonepe`,
});

export const Project = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/project`,
});

export const Amount_split = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/amount_split`,
});

export const Attendance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/attendance`,
});

export const Ticket = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/ticket`,
});

export const Ticket_reply = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/ticket_reply`,
});

export const Practice = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/practice`,
});

export const Languages = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/languages`,
});

export const Languages_practice = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/languages_practice`,
});

export const Category = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NEW}/category`,
});

export const Question = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NEW}/question`,
});

export const Attempt = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_NEW}/attempt`,
});

export const Certificate = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL_CERTIFICATE}/api`,
});

export const Resume = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/resume`,
});

export const Entrolluser = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/entrolluser`,
});

export const Contactuser = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/contactuser`,
});

export const Cover = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/cover`,
});

export const Questiondata = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/question`,
});

export const Courseproject = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/courseproject`,
});

export const Coupon = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/coupon`,
});

export const Interestlead = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/interestlead`,
});

export const Staffattendance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/staffattendance`,
});

export const Invoive = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/invoive`,
});

export const Batch = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/batch`,
});

export const Batch_log = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/batch_log`,
});

export const Techstack = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/techstack`,
});