/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UpdateBatch, ViewByidBatch } from "../../Api/batch";
import { ViewCoursesInfo } from "../../Api/courses";
import { viewallstaff } from "../../Api/staff";
import { updateModules } from "../../Api/modules";
import { createBatch_log } from "../../Api/batch_log";

const Module = () => {
  let { id } = useParams();
  var adminid =
    sessionStorage.getItem("adminid") || localStorage.getItem("adminid");
  var role = sessionStorage.getItem("role") || localStorage.getItem("role");

  const [batchdata, setbatchdata] = useState([]);
  const [moduledata, setmoduledata] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setloading] = useState(false);
  const [staffdata, setstaffdata] = useState([]);
  const [singlestaff, setsinglestaff] = useState([]);
  const [techdata, settechdata] = useState([]);

  useEffect(() => {
    GetCourseInfo();
  }, [id]);

  const GetCourseInfo = async () => {
    const batchResponse = await ViewByidBatch({ id: id });
    if (batchResponse.length !== 0) {
      // setloading(true);
      const batch = batchResponse[0];
      setbatchdata([batch]);

      const completedModules = batch.completed_module
        ? batch.completed_module.split(",")
        : [];
      if (
        completedModules.length !== 0 &&
        completedModules.join(",") !== selectedItems.join(",")
      ) {
        setSelectedItems(completedModules);
      }
      const courseResponse = await ViewCoursesInfo({ id: batch.type });
      if (courseResponse.length !== 0) {
        const course = courseResponse[0];
        if (course.modules.length !== 0 && course.modules !== moduledata) {
          setmoduledata(course.modules);
          // setloading(false);
        }
        if (course.technologies.length !== 0) {
          settechdata(course.technologies);
        }
      }
    }
    var allstaffdata = await viewallstaff();
    setstaffdata(allstaffdata);
    if (allstaffdata.length !== 0) {
      var singlestaff = await allstaffdata.filter((data) => {
        return data.id == adminid;
      });
      setsinglestaff(singlestaff);
    }
  };

  const handleCheckboxChange = async (id) => {
    setSelectedItems((prevSelected) => {
      const updatedItems =
        prevSelected !== undefined && prevSelected.includes(String(id))
          ? prevSelected.filter((item) => item !== String(id))
          : [...prevSelected, String(id)];
      const stringdata = updatedItems.join(",");
      const obj = {
        id: batchdata[0].id,
        completed_module: stringdata,
      };
      var singlemodule = moduledata.filter((data) => {
        return data.id == id;
      });
      var newobj = {
        type: "Batch Module completed",
        message:
          role == "admin"
            ? `${singlemodule[0].title} Module completed by admin`
            : `${singlemodule[0].title} Module completed by staff ${singlestaff[0].firstname} ${singlestaff[0].lastname}`,
        batchid: batchdata[0].id,
        role: role,
      };

      UpdateBatch(obj).then(async (updatedata) => {
        if (updatedata === "Updated Successfully") {
          await createBatch_log(newobj);
          GetCourseInfo();
        }
      });
      return updatedItems;
    });
  };

  const handleSelectStaff = async (data, staffId) => {
    await updateModules({ id: data.id, author: staffId });
    GetCourseInfo();
  };
  return (
    <div>
      <h1 className="text-xl font-bold mt-5 text-green-600">Module List</h1>
      <hr className="border border-gray-500 my-2" />
      {techdata.length !== 0 ? (
        <div className="stepper">
          {techdata.map((item, index) => {
            const moduleArray = JSON.parse(item.modules);
            const isActive = moduleArray.every((module) =>
              selectedItems.includes(String(module))
            );

            return (
              <React.Fragment key={index}>
                <div className={`step ${isActive ? "active" : ""}`}>
                  <div className="circle-container">
                    <span className="circle">{isActive ? "✔" : "✔"}</span>
                    <span className="label">{item.name}</span>
                  </div>
                </div>
                {index < techdata.length - 1 && <div className="line"></div>}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      <table className="w-full text-center my-5">
        <thead>
          <tr>
            <th>Day</th>
            <th>Heading</th>
            <th>Syllabus</th>
            <th>Select</th>
            <th>Task</th>
          </tr>
        </thead>
        <tbody>
          {loading === false ? (
            moduledata.length !== 0 ? (
              (() => {
                let moduleDayCounter = 0;

                return moduledata.map((data, index) => {
                  const currentDay =
                    data.type === "Module" ? `Day ${++moduleDayCounter}` : null;

                  return (
                    <tr key={index}>
                      <td>{currentDay}</td>
                      <td>{data.title}</td>

                      {data.type === "Module" ? (
                        <>
                          <td>
                            <ul className="list-disc pl-5 text-start">
                              {data.lesson_data && data.lesson_data.length !== 0
                                ? data.lesson_data.map((item, lessonIndex) => (
                                    <li key={lessonIndex}>{item.title}</li>
                                  ))
                                : "No lessons available"}
                            </ul>
                          </td>
                          <td>
                            <input
                              className="mr-2"
                              type="checkbox"
                              id={`checkbox-${data.id}`}
                              checked={
                                selectedItems !== undefined &&
                                selectedItems.includes(String(data.id))
                              }
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td>
                            {data.task.length !== 0 ? (
                              <button
                                className="bg-blue-500 text-white p-2 rounded"
                                onClick={() =>
                                  window.open(data.task, "__blank")
                                }
                              >
                                View
                              </button>
                            ) : (
                              "No task available"
                            )}
                          </td>
                        </>
                      ) : data.type === "Evaluation" ? (
                        <>
                          <td colSpan={1}>
                            <select
                              className="p-2 border rounded w-[80%]"
                              onChange={(e) =>
                                handleSelectStaff(data, e.target.value)
                              }
                              value={data.author}
                            >
                              <option value="">Select Staff</option>
                              {staffdata.length !== 0 &&
                                staffdata.map((staff) => (
                                  <option key={staff.id} value={staff.id}>
                                    {staff.firstname} {staff.lastname}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td>
                            <input
                              className="mr-2"
                              type="checkbox"
                              id={`checkbox-${data.id}`}
                              checked={
                                selectedItems !== undefined &&
                                selectedItems.includes(String(data.id))
                              }
                              onChange={() => handleCheckboxChange(data.id)}
                            />
                          </td>
                          <td>No task available</td>
                        </>
                      ) : null}
                    </tr>
                  );
                });
              })()
            ) : (
              <tr>
                <td colSpan={7}>No Modules Available</td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={7}>Please Wait...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Module;
